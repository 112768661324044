import React from 'react';

const URLForm = ({ realtorLink, handleUrlChange, handleSubmit }) => {
  return (
    <section className="section form-section">
      <h2>Realtor Property Submission</h2>
      <form onSubmit={handleSubmit}>
        <label htmlFor="propertyLink">Enter Shareable Property Link:</label>
        <input
          placeholder='https://mysharelink.com'
          type="url"
          id="propertyLink"
          value={realtorLink}
          onChange={handleUrlChange}
          required
        />
        <button type="submit">Submit Property</button>
      </form>
    </section>
  );
};

export default URLForm;
