import React from 'react';

const LeadTable = () => {
  return (
    <div className="benefit">
      <h3>Capture Leads with Ease</h3>
      <p>Our QR codes capture valuable leads by offering a seamless way to engage with potential homebuyers. Below is a sample of the lead data collected:</p>
      <table className="lead-table">
        <thead>
          <tr>
            <th>MLS</th>
            <th>Date</th>
            <th>Name</th>
            <th>Phone</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>12345</td>
            <td>2024-09-15</td>
            <td>John Doe</td>
            <td>(555) 123-4567</td>
          </tr>
          <tr>
            <td>67890</td>
            <td>2024-09-12</td>
            <td>Jane Smith</td>
            <td>(555) 987-6543</td>
          </tr>
          <tr>
            <td>11223</td>
            <td>2024-09-10</td>
            <td>Mark Johnson</td>
            <td>(555) 222-3333</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default LeadTable;
