import React from 'react';

const LogoGrid = ({ logos }) => {
  return (
    <div className="benefit">
      <h3>Exclusive Discounts</h3>
      <p>When homebuyers scan the QR codes, they receive exclusive discounts on home-related products such as furniture, services, linens, kitchenware, and more.</p>
      <div className="logo-cards-container">
        {logos.map((logo, index) => (
          <div key={index} className="logo-card">
            <img src={logo} alt={`Logo ${index}`} className="logo-image" />
          </div>
        ))}
      </div>
    </div>
  );
};

export default LogoGrid;
