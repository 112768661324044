import React from 'react';
import { useLocation } from 'react-router-dom';

function Listing() {
  const query = new URLSearchParams(useLocation().search);
  const url = query.get('url'); // Extract the 'url' query param
  const room = query.get('room') || 'Home'; // Default to 'Home' if no room param

  // Hardcoded sample coupons for each room
  const coupons = {
    Home: ["10% off Furniture", "20% off Home Decor"],
    Kitchen: ["15% off Kitchen Appliances", "Buy 1 Get 1 Free Cookware"],
    Living: ["10% off Sofas", "20% off Rugs"],
    Dining: ["25% off Dining Sets", "15% off Tableware"],
    Family: ["30% off Family Room Furniture", "Buy 2 Get 1 Free Board Games"],
    Primary: ["20% off Bedding", "Free Pillow with Purchase"],
    Patio: ["15% off Outdoor Furniture", "Free Grill Cover with Purchase"],
    Garage: ["10% off Tools", "Buy 1 Get 1 Free Storage Bins"],
    Bedroom: ["15% off Mattresses", "Free Blanket with Purchase"],
  };

  const roomCoupons = coupons[room] || coupons['Home']; // Use the 'Home' coupons as a fallback

  return (
    <div>
      <h2>Listing Page</h2>
      
      {url ? (
        <iframe
          src={url}
          title="Property Listing"
          width="100%"
          height="600px"
          style={{ border: '1px solid #ccc' }}
        ></iframe>
      ) : (
        <p>No URL provided.</p>
      )}

      {/* Display relevant coupons based on the room */}
      <section className="coupons-section">
        <h3>{room} Coupons</h3>
        <ul>
          {roomCoupons.map((coupon, index) => (
            <li key={index}>{coupon}</li>
          ))}
        </ul>
      </section>
    </div>
  );
}

export default Listing;
