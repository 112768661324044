import React, { useState, useEffect } from 'react';
import { QRCodeCanvas } from 'qrcode.react'; 
import './App.css';
import LeadTable from './LeadTable';
import LogoGrid from './LogoGrid';
import URLForm from './URLForm';

// Import images dynamically
const importAll = (r) => r.keys().map(r);
const logos = importAll(require.context('./images', false, /\.(png|jpe?g|svg)$/));

// Room names for the additional QR codes
const rooms = ["House","Kitchen", "Living", "Dining", "Family", "Primary", "Patio", "Garage", "Bedroom"];

function Home() {
  const [realtorLink, setRealtorLink] = useState('');
  const [submittedLink, setSubmittedLink] = useState('');
  const [iframeError, setIframeError] = useState(false);
  const [newUrl, setNewUrl] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isValidUrl(realtorLink)) {
      setSubmittedLink(realtorLink);
      setIframeError(false);
    } else {
      setIframeError(true);
    }
  };

  const handleNewUrlSubmit = (e) => {
    e.preventDefault();
    if (isValidUrl(newUrl)) {
      setSubmittedLink(newUrl);
      setIframeError(false);
      setNewUrl('');
    } else {
      setIframeError(true);
    }
  };

  const handleUrlChange = (e) => {
    setRealtorLink(e.target.value);
  };

  useEffect(() => {
    if (submittedLink) {
      const iframe = document.getElementById('propertyIframe');
      try {
        const iframeDoc = iframe.contentWindow.document;
      } catch (e) {
        setIframeError(true);
      }
    }
  }, [submittedLink]);

  const isValidUrl = (string) => {
    try {
      new URL(string);
      return true;
    } catch (_) {
      return false;
    }
  };

  const qrCodeLink = `${window.location.origin}/listing?url=${submittedLink}`;

  return (
    <div className="App">
      <header className="header">
        <h1>Welcome to RealtyTag</h1>
        <p>Maximize your property listings with QR codes that capture leads and provide home-buying-related coupons.</p>
      </header>

      {/* Section for Real Estate Agents */}
      <section className="section">
        <h2>For Real Estate Agents</h2>
        <LeadTable />
      </section>

      {/* Section for Homebuyers */}
      <section className="section">
        <h2>For Homebuyers</h2>
        <LogoGrid logos={logos} />
      </section>

      {/* Realtor Property Submission Form */}
      <URLForm realtorLink={realtorLink} handleUrlChange={handleUrlChange} handleSubmit={handleSubmit} />

      {/* Submitted Link Section with side-by-side layout */}
      {submittedLink && (
        <section className="iframe-container">
          {!iframeError ? (
            <>
              <div className="iframe-section">
                <iframe
                  id="propertyIframe"
                  src={submittedLink}
                  title="Property Preview"
                  width="100%"
                  height="500px"
                ></iframe>
              </div>
              <div className="qr-code-container">
                <h3>Scan the QR Code:</h3>
                <QRCodeCanvas value={qrCodeLink} size={150} />
              </div>
            </>
          ) : (
            <div>
              <p>The property link cannot be embedded due to cross-origin restrictions.</p>
              <p>Please provide a new embeddable URL:</p>
              <form onSubmit={handleNewUrlSubmit}>
                <input
                  type="url"
                  id="newUrl"
                  value={newUrl}
                  onChange={(e) => setNewUrl(e.target.value)}
                  required
                />
                <button type="submit">Submit New URL</button>
              </form>
            </div>
          )}
        </section>
      )}

      {/* Additional QR codes for each room */}
      {submittedLink && (
        <section className="section">
          <h2>QR Codes for Each Room</h2>
          <div className="qr-code-room-container">
            {rooms.map((room) => {
              const roomQrLink = `${window.location.origin}/listing?url=${submittedLink}&room=${room}`;
              return (
                <div key={room} className="qr-code-room">
                  <h4>{room} QR Code</h4>
                  <QRCodeCanvas value={roomQrLink} size={120} />
                </div>
              );
            })}
          </div>
        </section>
      )}

      <footer className="footer">
        <p>&copy; 2024 RealtyTag | Connect your listings, capture leads, provide value!</p>
      </footer>
    </div>
  );
}

export default Home;
